import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from './auth/reducer';
import deviceReducer from './devices/reducer';
import hardwareReducer from './hardwares/reducer';
import programReducer from './programs/reducer';

import { actionConstants } from '@/redux/auth/action';



const config = {
  key: 'devices.machineKey',
  storage: storage,
};

const reducers = combineReducers({
	auth: authReducer,
	devices: deviceReducer,
	hardwares: hardwareReducer,
	programs: programReducer
});


const rootReducer = (state: any, action:any) => {
	if (action.type === actionConstants.REQUEST_TURN_OFF) {
		return reducers(undefined, action)
	 }
  
	 return reducers(state, action)
};

const persisted = persistReducer(config, rootReducer);


export default persisted;
const actionConstants = {
    SET_USER_DEVICES: 'SET_USER_DEVICES',
    SET_CURRENT_MACHINE: 'SET_CURRENT_MACHINE',
    SET_CURRENT_MACHINE_KEY: 'SET_CURRENT_MACHINE_KEY',
    SET_INSTALLED_PROGRAMS: 'SET_INSTALLED_PROGRAMS',
  }
  
  const	setUserDevices = (data: any) => ({
    type: actionConstants.SET_USER_DEVICES,
    data
  });

  const	setCurrentMachine = (data: any) => ({
    type: actionConstants.SET_CURRENT_MACHINE,
    data
  });


  const	setCurrentMachineKey = (machineKey: string) => ({
    type: actionConstants.SET_CURRENT_MACHINE_KEY,
    machineKey
  });


  const	setInstalledPrograms = (data: any[]) => ({
      type: actionConstants.SET_INSTALLED_PROGRAMS,
      data
  })

  export {
    actionConstants,
    setCurrentMachine,
    setCurrentMachineKey,
    setUserDevices,
    setInstalledPrograms
  };
  
import { all, call, put, select, takeLatest } from "redux-saga/effects";


// REDUX ACTIONS
import {
  actionConstants, setHardwareDefinition
} from './action';

// API
import { getHardwareDefinitions, purchaseHardware } from '@/api/hardwares';

// TYPES
import { RootState } from '@/types/redux';


type DefaultResponse  ={
  [key: string]: any
}

const getUser = (state:RootState) => state.auth;

function* hardware({type, id} : {
  type: typeof actionConstants.REQUEST_HARDWARE_DEFINITION,
  id: number
}) {
  try {
    const auth: ReturnType<typeof getUser> = yield select(getUser);
  
    const hardwareResponse: DefaultResponse = yield call(getHardwareDefinitions, id, auth.user.id);
    yield put(setHardwareDefinition(hardwareResponse))

  } catch (error:any) {
    if(error && error.response && error.response) {
      yield put(setHardwareDefinition(error.response.data))
    }
   
  }
}



function* buyHardware({ type, hardwareId } : {
    type: typeof actionConstants.REQUEST_PURCHASE_HARDWARE,
    hardwareId: string,
  }) {
    try{
      const auth: ReturnType<typeof getUser> = yield select(getUser);
  
      const hardwareProgramResponse: DefaultResponse =  yield call(purchaseHardware, hardwareId,auth.user.id);
      console.log('hardwareProgramResponse',hardwareProgramResponse)
    }catch(error) {
      
    }
  }
  


export default function* hardwareSaga() {
  yield all([
    takeLatest(actionConstants.REQUEST_HARDWARE_DEFINITION, hardware),
    takeLatest(actionConstants.REQUEST_PURCHASE_HARDWARE, buyHardware),
  ]);

}
// import { useState } from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { useConnection, useWallet } from '@solana/wallet-adapter-react';

// SHARED COMPONENTS
// import CustomWallet from '@/components/Wallet';

// REDUX
import { setCurrentUser } from '@/redux/auth/action';

// LIB
// import { encodeSignature, encodeText, verifySignature } from '@/lib/signature';

// API
import { authUser, createUserV2 } from '@/api/auth';
// createUser, getUser
import Layout from './Layout';

// const CREATE_SIGNATURE_MESSAGE = `I'm ready to join the Shadow Network`;

// const SIGNIN_SIGNATURE_MESSAGE = `I'm ready to enter the Shadow Network - `;

// const ApproveLogin = () => {
//     const { connection } = useConnection();
//     const { publicKey, signMessage } = useWallet();

//     const dispatch = useDispatch();

//     useEffect(() => {
//         const initializeSignMessage = async () => {
//             // try {
//             //     if (!publicKey) throw new Error('Wallet not connected!');
//             //     else {
//             //         if (!signMessage)
//             //             throw new Error(
//             //                 'Wallet does not support message signing!'
//             //             );

//             //         const existingUser: any = await getUser(`${publicKey}`);
//             //         let currentUser = {
//             //             link_id: '',
//             //             nonce: '',
//             //         };

//             //         let message: Uint8Array | null = null;
//             //         let signature: Uint8Array | null = null;
//             //         if (
//             //             existingUser &&
//             //             existingUser.link &&
//             //             existingUser.link.id
//             //         ) {
//             //             currentUser = {
//             //                 link_id: existingUser.link.id,
//             //                 nonce: existingUser.link.nonce,
//             //             };
//             //             message = encodeText(
//             //                 `${SIGNIN_SIGNATURE_MESSAGE}${currentUser.nonce}`
//             //             );
//             //             signature = await signMessage(message);
//             //         } else {
//             //             message = encodeText(`${CREATE_SIGNATURE_MESSAGE}`);
//             //             signature = await signMessage(message);
//             //             const createdUser: any = await createUser(
//             //                 `${publicKey}`,
//             //                 encodeSignature(signature)
//             //             );

//             //             currentUser = {
//             //                 link_id: createdUser.link.id,
//             //                 nonce: createdUser.link.nonce,
//             //             };
//             //         }

//             //         if (
//             //             !verifySignature(
//             //                 message,
//             //                 signature,
//             //                 publicKey.toBytes()
//             //             )
//             //         ) {
//             //             throw new Error('Invalid signature!');
//             //         }

//             //         const authResponse = await authUser({
//             //             signature: encodeSignature(signature),
//             //             link_id: currentUser.link_id,
//             //         });

//             // if (authResponse) {
//             //     dispatch(setCurrentUser(authResponse));
//             // }
//             //     }
//             // } catch (error: any) {
//             //     console.log(`Signing failed: ${error?.message}`);
//             // }

//             try {
//                 const authResponse = await authUser({
//                     link_id:
//                         'iLnkiUsr0x1E8X7n4VciUsr0x1E8X7n4VciUsr0x1E8X7n4Vc',
//                     temp_user_id:
//                         'iLnkiUsr0x1E8X7n4VciUsr0x1E8X7n4VciUsr0x1E8X7n4Vc',
//                 });

//                 if (authResponse) {
//                     console.log('authResponse', authResponse);
//                     dispatch(setCurrentUser(authResponse));
//                 }
//             } catch (err) {
//                 console.log('authResponse err', err);
//             }
//         };
//         initializeSignMessage();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [connection, `${publicKey}`]);

//     return <div></div>;
// };

const SignIn = () => {
    const dispatch = useDispatch();

    // const [userId, setUserId] = useState<string>('');

    // const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setUserId(e.target.value);
    // };

    const handleSubmit = async () => {
        try {
            // SAMPLE USER ID = iLnkiUsr0x1E8X7n4VciUsr0x1E8X7n4VciUsr0x1E8X7n4Vc'
            // if (userId) {
            //     const authResponse = await authUser({
            //         link_id: userId,
            //         temp_user_id: userId,
            //     });
            //     if (authResponse) {
            //         dispatch(setCurrentUser(authResponse));
            //     } else {
            //         alert('Login Failed!');
            //     }
            // }

            const createUserResponse = await createUserV2();

            if (createUserResponse?.link_context?.id) {
                const authResponse = await authUser({
                    link_id: createUserResponse?.link_context?.id,
                    temp_user_id: createUserResponse?.link_context?.id,
                });
                console.log('Auth auth', createUserResponse);
                if (authResponse) {
                    dispatch(setCurrentUser(authResponse));
                } else {
                    alert('Login Failed!');
                }
            } else {
                alert('Failed to create user!');
            }
        } catch (err) {
            console.log('Auth User Error', err);
        }
    };
    return (
        <Layout>
            <div className="flex h-screen">
                <div className="m-auto  w-2/5">
                    <div className="block p-4 content-center items-center">
                        <div className="flex justify-center">
                            {/* <CustomWallet>
                                <ApproveLogin />
                            </CustomWallet> */}

                            <div className="block w-full m-30 p-2 content-center items-center">
                                {/* <div className="flex justify-center w-full">
                                    <input
                                        onChange={handleUserIdChange}
                                        type="text"
                                        className="w-full border border-black text-center"
                                        name="user_id"
                                        placeholder="User ID"
                                    />
                                </div> */}
                                <div>
                                    <button
                                        onClick={handleSubmit}
                                        className="w-full border border-black bg-white py-2 px-12 my-2 text-black"
                                        type="button"
                                    >
                                        Enter the Shadow Network
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default SignIn;

import { lazyImport } from '@/utils/lazyImport';

const { MachineRoutes } = lazyImport(
    () => import('@/pages/Desktop'),
    'MachineRoutes'
);

export const protectedRoutes = [
    {
        path: '/main/*',
        element: <MachineRoutes />,
    },
];

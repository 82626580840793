import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import hardwareSaga from './hardwares/saga';
import programSaga from './programs/saga';

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    authSaga(),
    hardwareSaga(),
    programSaga()
  ]);
}
import { all, call, put, takeLatest } from "redux-saga/effects";


// REDUX ACTIONS
import {
  actionConstants
} from './action';
import { setUserDevices } from '@/redux/devices/action';

// API
import { getDevices } from '@/api/devices';

type DeviceResponse = {
  virtual_machines: []
}


function* setUserInfo(action: any) {
  try {
    const { data } = action;

    const deviceResponse:DeviceResponse = yield call(getDevices, data.id)
    yield put(setUserDevices(deviceResponse.virtual_machines))

  } catch (error) {

  }
}


export default function* authSaga() {
  yield all([
    takeLatest(actionConstants.SET_CURRENT_USER, setUserInfo),
  ]);

}
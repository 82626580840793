const storagePrefix = 'snet_app_';

const storage = {
  getStorage: (key: string) => {
    return window.localStorage.getItem(`${storagePrefix}${key}`) as string;
  },
  setStorage: (key: string,value: string) => {
    window.localStorage.setItem(`${storagePrefix}${key}`, value);
  },
  clearStorage: (key: string) => {
    window.localStorage.removeItem(`${storagePrefix}${key}`);
  },
};

export default storage;
import api from '@/lib/api';
import { AxiosResponse } from 'axios';

const getHardwareDefinitions = async ( type: number, userId: string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.get(`/content/hardware?hardwareType=${type}`,
        {
            headers:{
                user_id: userId
            }
        });
    } catch(err) {
        return err;
    }
}

const purchaseHardware = async ( hardwareId: string, userId: string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.post(`/users/hardware/${hardwareId}`, 
        {},
        {
            headers:{
                user_id: userId
            }
        });
    } catch(err) {
        return err;
    }
}




export  {
    getHardwareDefinitions,
    purchaseHardware
}
import { AppRoutes } from '@/routes';
import { AppProvider } from '@/providers';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { store } from '@/redux/store';

declare global {
    interface Window {
        solana: any;
    }
}

const persistor = persistStore(store);

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AppProvider>
                    <AppRoutes />
                </AppProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;

import Axios, { AxiosError } from 'axios';

import storage from '@/utils/storage';

function authRequestInterceptor(config: any) {
  const token = storage.getStorage('token');
  if (token) {
    config.headers.authorization = `${token}`;
  }
  config.headers.Accept = 'application/json';
  config.headers['Content-Type'] = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.crossDomain = true;
  return config;
}

const api = Axios.create({
  baseURL: 'https://dev1.snet.run'
});

api.interceptors.request.use(authRequestInterceptor);
api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error: AxiosError) => {

    (error as any).originalMessage = error.message;
    Object.defineProperty(error, "message", { get: function () {
        if (!error.response) {
            return (error as any).originalMessage;
        }
        return JSON.stringify(error.response.data);
    }});
    return Promise.reject(error);
  }
);

export default api;
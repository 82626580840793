import api from '@/lib/api';
import { AxiosResponse } from 'axios';



const scanDevices = async (deviceId:  string, programId: string, userId: string): Promise<AxiosResponse | unknown>  => {
    return await api.post(`/machines/${deviceId}/programs/${programId}`,
    {
        args : {
            ipv_4_address : [] 
      }
  },{
        headers:{
            user_id: userId
        }
    });
}
const haltProgram = async (deviceId:  string, programId: string, activeId: string, userId: string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.patch(`/machines/${deviceId}/programs/${programId}?activeId=${activeId}`,
        {},{
            headers:{
                user_id: userId
            }
        });
    } catch(err) {
        return err;
    }
}


const analyzeProgram = async (deviceId:  string, targetDeviceId: string, programId: string, userId: string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.post(`/machines/${deviceId}/programs/${programId}`,
        {
           args: {
              id: targetDeviceId
           }
        },{
            headers:{
                user_id: userId
            }
        });
    } catch(err) {
        return err;
    }
}

const exploitedMachines = async (machineId:  string, userId: string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.get(`/machines/${machineId}/exploited`,
        {
            headers:{
                user_id: userId
            }
        });
    } catch(err) {
        return err;
    }
}



const clearLogs = async (machineId:  string, userId: string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.put(`/machines/${machineId}/logs`,
        {
        bytes:[]
        },
        {
            headers:{
                user_id: userId
            }
        })
    } catch(err) {
        return err;
    }
}


const programDefinitions = async ( userId: string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.get(`/content/programs`,
        {
            headers:{
                user_id: userId
            }
        });
    } catch(err) {
        return err;
    }
}



const hardwareDefinitions = async ( type: number, userId: string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.get(`/content/hardware?hardwareType=${type}`,
        {
            headers:{
                user_id: userId
            }
        });
    } catch(err) {
        return err;
    }
}




const purchaseProgram = async ( programId: string, userId: string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.post(`/users/programs/${programId}`, 
        {},
        {
            headers:{
                user_id: userId
            }
        });
    } catch(err) {
        return err;
    }
}




export {
    analyzeProgram,
    haltProgram,
    scanDevices,
    exploitedMachines,
    clearLogs,
    hardwareDefinitions,
    programDefinitions,
    purchaseProgram
}
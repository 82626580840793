import { actionConstants } from './action';



interface DeviceReducer  {
    isLoading: boolean,
	currentMachine: any,
	owned: any[],
	machineKey?: string
};

const initialState: DeviceReducer = {
	isLoading: false,
	currentMachine: null,
	owned:[],
	machineKey:''
};

const devices = (state = initialState, action: any) => {
	switch (action.type) {
	
			case actionConstants.SET_USER_DEVICES:
				return {
					...state,
					error: {},
					isLoading: false,
					owned: [...(action ? action.data : [])]
				};

			case actionConstants.SET_CURRENT_MACHINE:
				return {
					...state,
					error: {},
					isLoading: false,
					currentMachine: {...(action ? action.data : {})},
					machineKey: action && action.data ? action.data.id : ''
				};
			case actionConstants.SET_CURRENT_MACHINE_KEY:
				return {
					...state,
					error: {},
					isLoading: false,
					machineKey: action.machineKey
				};
			case actionConstants.SET_INSTALLED_PROGRAMS: 
				return {
					...state,
					error: {},
					isLoading: false,
					currentMachine: {
						...state.currentMachine,
						installed_programs: [...(action.data || [])]

					},
				};
	
			
					
		default:
			return state;
	}
};


export default devices;

import api from '@/lib/api';
import { AxiosResponse } from 'axios';



const getDevices = async (userId:  string): Promise<AxiosResponse | unknown>  => {
    try{
        return await api.get(`/machines`,{
            headers:{
                user_id: userId
            }
        });

        
    } catch(err) {
        return err;

    }
}

export {
    getDevices
}
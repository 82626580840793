import { actionConstants } from './action';

// TYPES
import { ExploitedMachines, Programs } from '@/types/programs';


interface ProgramReducer  {
    isScanLoading: boolean,
	scannedDevices: any,
	scanErrorMessage: string,
	exploitedMachines: ExploitedMachines[],
	programDefinitions: Programs[],
	hardwareDefinitions: any
};

const initialState:ProgramReducer  = {
	isScanLoading: false,
	scannedDevices: null,
	scanErrorMessage: '',
	exploitedMachines: [],
	programDefinitions: [],
	hardwareDefinitions: []
};

const programs = (state = initialState, action: any) => {
	switch (action.type) {
		case actionConstants.REQUEST_SCAN:
			return {
				...state,
				error: {},
				isScanLoading: true,
				scanErrorMessage: ''
			};
			case actionConstants.SET_SCANNED_DEVICES:
				return {
					...state,
					scanErrorMessage: '',
					isScanLoading: false,
					scannedDevices: {...(action ? action.data : {})}
				};
			case actionConstants.REQUEST_SCAN_ERROR: 
				return {
				...state,
				scanErrorMessage: action.error,
				isScanLoading: false,
				scannedDevices: null
			};
			case actionConstants.SET_EXPLOITED_MACHINES:
				return {
					...state,
					exploitedMachines: [...action.data || []],

				};
			case actionConstants.SET_PROGRAM_DEFINITIONS:
				return {
					...state,
					programDefinitions: [...action.data || []],
	
				};
				case actionConstants.SET_HARDWARE_DEFINITIONS:
					return {
						...state,
						hardwareDefinitions: [...action.data || []],
		
					};
						
				
		default:
			return state;
	}
};

export default programs;

const actionConstants = {
    REQUEST_SCAN: 'REQUEST_SCAN',
    REQUEST_HALT_PROGRAM: 'REQUEST_HALT_PROGRAM',
    REQUEST_ANALYZE_PROGRAM: 'REQUEST_ANALYZE_PROGRAM',
    SET_SCANNED_DEVICES: 'SET_SCANNED_DEVICES',
    REQUEST_SCAN_ERROR: 'REQUEST_SCAN_ERROR',
    REQUEST_CLEAR_LOGS: 'REQUEST_CLEAR_LOGS',
    REQUEST_EXPLOITED_MACHINES: 'REQUEST_EXPLOITED_MACHINES',
    SET_EXPLOITED_MACHINES: 'SET_EXPLOITED_MACHINES',
    REQUEST_PROGRAM_DEFINITIONS: 'REQUEST_PROGRAM_DEFINITIONS',
    REQUEST_HARDWARE_DEFINITIONS: 'REQUEST_HARDWARE_DEFINITIONS',
    REQUEST_PURCHASE_PROGRAM: 'REQUEST_PURCHASE_PROGRAM',
    SET_PROGRAM_DEFINITIONS: 'SET_PROGRAM_DEFINITIONS',
    SET_HARDWARE_DEFINITIONS: 'SET_HARDWARE_DEFINITIONS'
  }
  
  const	requestScan = (machineId: string, programId: string) => ({
    type: actionConstants.REQUEST_SCAN,
    machineId,
    programId
  });


  const	setScannedDevices = (data: any) => ({
    type: actionConstants.SET_SCANNED_DEVICES,
    data
  });

  const	requestHaltprogram = (deviceId: string, programId: string, activeId: string) => ({
    type: actionConstants.REQUEST_HALT_PROGRAM,
    deviceId,
    programId,
    activeId
  });

  const	requestAnalyzeProgram = (deviceId: string, targetDeviceId: string, programId: string ) => ({
    type: actionConstants.REQUEST_ANALYZE_PROGRAM,
    deviceId,
    targetDeviceId,
    programId
  });
  
  const	requestScanError = (error: string) => ({
    type: actionConstants.REQUEST_SCAN_ERROR,
    error
  });


  const requestClearLogs = (machineId: string) => ({
    type: actionConstants.REQUEST_CLEAR_LOGS,
    machineId
  })


  const requestExploitedMachines = (machineId: string) => ({
    type: actionConstants.REQUEST_EXPLOITED_MACHINES,
    machineId
  });

  const	setExploitedMachines = (data: any) => ({
    type: actionConstants.SET_EXPLOITED_MACHINES,
    data
  });

  const requestProgramDefinitions = () => ({
    type: actionConstants.REQUEST_PROGRAM_DEFINITIONS
  });


  const requestHardwareDefinitions = (hardwareType: number) => ({
    type: actionConstants.REQUEST_HARDWARE_DEFINITIONS,
    hardwareType
  });


  const setProgramDefinitions = (data: any) => ({
    type: actionConstants.SET_PROGRAM_DEFINITIONS,
    data
  });

  const setHardwareDefinitions = (data: any) => ({
    type: actionConstants.SET_HARDWARE_DEFINITIONS,
    data
  });

  const requestPurchaseProgram = (programId: string) => ({
    type: actionConstants.REQUEST_PURCHASE_PROGRAM,
    programId
  });

  export {
    actionConstants,
    requestScan,
    requestHaltprogram,
    requestAnalyzeProgram,
    requestScanError,
    requestClearLogs,
    requestExploitedMachines,
    requestHardwareDefinitions, 
    requestProgramDefinitions,
    requestPurchaseProgram,
    setScannedDevices,
    setExploitedMachines,
    setProgramDefinitions,
    setHardwareDefinitions
  };
  
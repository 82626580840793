import { AuthReducer } from '@/types/users'
import { actionConstants } from './action';



const initialState: AuthReducer = {
	hasPageAccess: false,
	isLoading: false,
	isSignupSuccess: false,
	isUpdateSuccess: false,
	isChangePasswordSuccess: false,
	isResetPasswordSuccess: false,
	error: {},
	user: null
};

const auth = (state = initialState, action: any) => {
	switch (action.type) {
		case actionConstants.REQUEST_SIGN_IN:
			return {
				...state,
				isLoading: true,
				error: {}
			};
		case actionConstants.REQUEST_SIGN_UP:
			return {
				...state,
				isLoading: true,
				isSignupSuccess: false,
				error: {}
			};

		case actionConstants.REQUEST_SIGN_UP_COMPLETE:
			return {
				...state,
				isLoading: false,
				isSignupSuccess: true,
				error: {}
			};


		case actionConstants.REQUEST_CURRENT_USER:
			return {
				...state,
				error: {},
				isLoading: true
			};

		case actionConstants.REQUEST_LOGOUT:
			return {
				...state,
				isLoading: false,
				user: null,
				error: {}
			};
		case actionConstants.SET_CURRENT_USER:
			return {
				...state,
				error: {},
				isLoading: false,
				user: {
					...(action ? action.data : {})
				}
			};
		case actionConstants.SET_PAGE_ACCESS:
			return {
				...state,
				hasPageAccess: action.value
			};
		default:
			return state;
	}
};

export default auth;

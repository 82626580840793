const actionConstants = {
    REQUEST_HARDWARE_DEFINITION: 'REQUEST_HARDWARE_DEFINITION',
    SET_HARDWARE_DEFINITIONS: 'SET_HARDWARE_DEFINITIONS',
    REQUEST_PURCHASE_HARDWARE: 'REQUEST_PURCHASE_HARDWARE'
  }
  
  const	requestHardwareDefinition = (id: string) => ({
    type: actionConstants.REQUEST_HARDWARE_DEFINITION,
    id
  });


  const	setHardwareDefinition = (data: any) => ({
    type: actionConstants.SET_HARDWARE_DEFINITIONS,
    data
  });

  const requestPurchaseHardware = (hardwareId: string) => ({
    type: actionConstants.REQUEST_PURCHASE_HARDWARE,
    hardwareId
  });


  export {
    actionConstants,
    requestHardwareDefinition,
    requestPurchaseHardware,
    setHardwareDefinition
  }
import { Navigate, useRoutes } from 'react-router-dom';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

// PAGES
import LockScreen from '@/pages/LockScreen';
import SignIn from '@/pages/Auth/components/SignIn';

// LIB
import { useAuthorization } from '@/lib/authorization';
// import { useAppSelector } from '@/lib/redux';

export const AppRoutes = () => {
    const { user, hasPageAccess } = useAuthorization();

    const isSignedIn = user ? true : false;

    const commonRoutes = [
        {
            path: '/',
            element: isSignedIn ? (
                <Navigate to="/main" />
            ) : hasPageAccess ? (
                <SignIn />
            ) : (
                <LockScreen />
            ),
        },
        //{ path: '/', element: <Desktop /> },
    ];

    const routes = isSignedIn ? protectedRoutes : publicRoutes;

    const element = useRoutes([...routes, ...commonRoutes]);

    return <>{element}</>;
};

import api from '@/lib/api';
import { AxiosResponse } from 'axios';

interface AuthUserPayload {
    link_id?: string,
    signature?: string
    temp_user_id: string
}


interface ExistingUser {
    link_id: string,
    nonce: string
}


// interface AuthUserResponse {
//     link_context: {
//         id: string,
//         nonce: string,
//         nonce_expiration: string
//     }
// }

const authUser = async (payload: AuthUserPayload) => {
    try{
        const { link_id } = payload;
        const response:any = await api.post('/auth?type=web', {
            link_id
          }
          
        );  
        return response.user_context;

    } catch(err) {
        console.log('api authUser err', err)
        return null;
    }
}

const createUser = async (publicAddress: string, signature: string | null): Promise<ExistingUser | AxiosResponse<any> | unknown>  => {
    try{
        const response = await api.post('/users', {
            public_address: publicAddress,
            signature
          });
          return response;
        
    }catch(err) {
        console.log('Error createUser', err)
        return err;
    }
}


const createUserV2 = async (): Promise<any> => {
    try {
        const response = await api.post('/users', {});
        return response;
        
    } catch(err) {
        console.log('Error createUserV2', err)
        return err;
    }
}

const getUser = async (publicAddress:  string): Promise<ExistingUser | AxiosResponse | unknown>  => {
    try{
        const response = await api.get(`/links?publicAddress=${publicAddress}`);
        return response;
        
    } catch(err) {
        console.log('Error', err)
        return err;

    }
}

export {
    createUser,
    createUserV2,
    getUser,
    authUser
}
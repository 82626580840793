import { UserCredentials, User } from '@/types/users';

const actionConstants = {
    REQUEST_SIGN_IN: 'REQUEST_SIGN_IN',
    REQUEST_CURRENT_USER: 'REQUEST_CURRENT_USER',
    REQUEST_SIGN_UP: 'REQUEST_SIGN_UP',
    REQUEST_SIGN_UP_COMPLETE: 'REQUEST_SIGN_UP_COMPLETE',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    REQUEST_LOGOUT: 'REQUEST_LOGOUT',
    SET_USER_DEVICES: 'SET_USER_DEVICES',
    SET_PAGE_ACCESS: 'SET_PAGE_ACCESS',
    REQUEST_TURN_OFF: 'REQUEST_TURN_OFF'
  }
  

  const	requestSignIn = (data: UserCredentials) => ({
    type: actionConstants.REQUEST_SIGN_IN,
    data
  });
  

  const	requestSignUp = (data: User) => ({
    type: actionConstants.REQUEST_SIGN_UP,
    data
  });

  
  const	requestSignUpComplete = () => ({
    type: actionConstants.REQUEST_SIGN_UP_COMPLETE
  });
  
  const	requestCurrentUser = (publicKey: any, signMessage: any) => ({
    type: actionConstants.REQUEST_CURRENT_USER,
    publicKey,
    signMessage
  });
  
  const	setCurrentUser = (data: User | null) => ({
    type: actionConstants.SET_CURRENT_USER,
    data
  });
  
  const	requestLogout = () => ({
    type: actionConstants.REQUEST_LOGOUT
  });

  const	setPageAccess = (value: boolean) => ({
    type: actionConstants.SET_PAGE_ACCESS,
    value
  });
  
  export {
    actionConstants,
    requestCurrentUser,
    requestSignIn,
    requestSignUp,
    requestSignUpComplete,
    requestLogout,
    setCurrentUser,
    setPageAccess

  };
  
import { useState } from 'react';
import { useDispatch } from 'react-redux';

// REDUX
import { setPageAccess } from '@/redux/auth/action';

// ASSETS
import SnetLogo from '@/assets/images/shadownetworklogo.jpg';

const LockScreen = () => {
    const dispatch = useDispatch();

    const [passPhrase, setPassPhrase] = useState('');

    const handlePassPhraseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassPhrase(e.target.value);
    };

    const handleSubmit = () => {
        if (passPhrase === process.env.REACT_APP_PASSPHRASE) {
            dispatch(setPageAccess(true));
        } else {
            alert('Failed');
        }
    };

    return (
        <div className="bg-black min-h-screen flex h-screen">
            <div className="w-3/12 m-auto">
                <div className="block m-30 p-2 content-center items-center">
                    <div>
                        <img src={SnetLogo} alt="snet" />
                    </div>
                    <div className="flex justify-center">
                        <input
                            onChange={handlePassPhraseChange}
                            type="password"
                            className="w-full text-center"
                            name="device_id"
                            placeholder="Passphrase"
                        />
                    </div>
                    <div>
                        <button
                            onClick={handleSubmit}
                            className="w-full bg-white py-2 px-12 my-2 text-black"
                            type="button"
                        >
                            Enter SNet
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LockScreen;

import { actionConstants } from './action';


interface HardwareReducer  {
    isHardwareLoading: boolean,
    hardwareDefinitions: any[]
	
};

const initialState:HardwareReducer  = {
	isHardwareLoading: false,
    hardwareDefinitions: []
};

const hardwares = (state = initialState, action: any) => {
	switch (action.type) {
		case actionConstants.REQUEST_HARDWARE_DEFINITION:
			return {
				...state,
				error: {},
				isHardwareLoading: true
			};
			case actionConstants.SET_HARDWARE_DEFINITIONS:
				return {
					...state,
					isHardwareLoading: false,
					hardwareDefinitions: [...(action ? action.data : [])]
				};
		
				
		default:
			return state;
	}
};

export default hardwares;

import { useSelector } from 'react-redux';

export const useAuthorization = () => {
    const { auth } = useSelector((state: any) => {
        return {
            auth: state.auth,
        };
    });

    return { user: auth && auth.user, hasPageAccess: auth.hasPageAccess };
};

import * as React from 'react';

type LayoutProps = {
    children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
    return (
        <div className="bg-auth-bg bg-cover bg-center min-h-screen">
            {children}
        </div>
    );
};
export default Layout;

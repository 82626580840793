import { all, call, put, select, takeLatest } from "redux-saga/effects";


// REDUX ACTIONS
import {
  actionConstants, setProgramDefinitions
} from './action';
import { requestScanError,setScannedDevices, setExploitedMachines, setHardwareDefinitions } from '@/redux/programs/action';
import { setUserDevices } from '@/redux/devices/action';


// API
import { analyzeProgram, scanDevices, clearLogs, exploitedMachines, programDefinitions, purchaseProgram, hardwareDefinitions } from '@/api/programs';

// TYPES
import { RootState } from '@/types/redux';

type ScanResponse  ={
  program: {
    [key: string]: any
  }
}


type DefaultResponse  ={
  [key: string]: any
}

const getUser = (state:RootState) => state.auth;
const getDeviceState = (state:RootState) => state.devices;

function* scanner({type, machineId,programId} : {
  type: typeof actionConstants.REQUEST_SCAN,
  machineId: string,
  programId: string
}) {
  try {
    const auth: ReturnType<typeof getUser> = yield select(getUser);
  
    const scanResponse: ScanResponse = yield call(scanDevices, machineId, programId,auth.user.id);
    yield put(setScannedDevices(scanResponse))

  } catch (error:any) {
    if(error && error.response && error.response) {
      yield put(requestScanError(error.response.data))
    }
    
   
  }
}

function* halt({type, deviceId,programId, activeId} : {
  type: typeof actionConstants.REQUEST_SCAN,
  deviceId: string,
  programId: string,
  activeId: string
}) {
  try {
    // const auth: ReturnType<typeof getUser> = yield select(getUser);
    // const haltResponse: DefaultResponse = yield call(haltProgram, deviceId, programId,activeId,auth.user.id)
    yield put(setScannedDevices([]))

  } catch (error) {

    yield put(setScannedDevices([]))
  }
}


function* analyze({type, deviceId, targetDeviceId,programId, activeId} : {
  type: typeof actionConstants.REQUEST_SCAN,
  deviceId: string,
  targetDeviceId: string,
  programId: string,
  activeId: string
}) {
  try {
    const auth: ReturnType<typeof getUser> = yield select(getUser);
    const analyzeResponse: DefaultResponse = yield call(analyzeProgram, deviceId, targetDeviceId, programId,auth.user.id)
    console.log('Analyze Response', analyzeResponse)

  } catch (error) {
  }
}


  function* deleteLogs({type, machineId} : {
    type: typeof actionConstants.REQUEST_CLEAR_LOGS,
    machineId: string,
  }){
    try{

      const auth: ReturnType<typeof getUser> = yield select(getUser);
      const devices: ReturnType<typeof getDeviceState> = yield select(getDeviceState);
      const logsResponse: DefaultResponse = yield call(clearLogs, machineId, auth.user.id);

      let ownedDevices = devices.currentMachine;

      ownedDevices.logs = logsResponse;
      yield put(setUserDevices(ownedDevices));
  
    }catch(error) {
      console.log('deleteLogs error', error)
    }
 }

 function* getExploitedMachines({type, machineId} : {
  type: typeof actionConstants.REQUEST_EXPLOITED_MACHINES,
  machineId: string,
}){
  try{
    const auth: ReturnType<typeof getUser> = yield select(getUser);
    const response:DefaultResponse =  yield call(exploitedMachines,machineId, auth.user.id);
    yield put(setExploitedMachines(response ? response.virtual_machines : []))
  }catch(error) {
    
  }
}


function* getProgramDefinitions({ type } : {
  type: typeof actionConstants.REQUEST_PROGRAM_DEFINITIONS
}) {
  try{
    const auth: ReturnType<typeof getUser> = yield select(getUser);
    const programResponse: DefaultResponse =  yield call(programDefinitions,auth.user.id);
    yield put(setProgramDefinitions(programResponse?.program_definitions_context))
  }catch(error) {
    yield put(setProgramDefinitions([]))
  }
}

function* getHardwareDefinitions({ type, hardwareType = 0} : {
  type: typeof actionConstants.REQUEST_PROGRAM_DEFINITIONS,
  hardwareType: number
}) {
  try{
    const auth: ReturnType<typeof getUser> = yield select(getUser);
    const hardwareResponse: DefaultResponse =  yield call(hardwareDefinitions,hardwareType,auth.user.id);
   
    if(hardwareResponse) { 
      const key = Object.keys(hardwareResponse)[0];
      yield put(setHardwareDefinitions(hardwareResponse[key]));
    }
  
  }catch(error) {
    yield put(setHardwareDefinitions([]))
  }
}


function* buyProgram({ type, programId } : {
  type: typeof actionConstants.REQUEST_PROGRAM_DEFINITIONS,
  programId: string,
}) {
  try{
    const auth: ReturnType<typeof getUser> = yield select(getUser);
    console.log('purchaseProgramResponse machineId',programId)
    const purchaseProgramResponse: DefaultResponse =  yield call(purchaseProgram, programId,auth.user.id);
    console.log('purchaseProgramResponse',purchaseProgramResponse)
  }catch(error) {
    
  }
}

export default function* programSaga() {
  yield all([
    takeLatest(actionConstants.REQUEST_SCAN, scanner),
    takeLatest(actionConstants.REQUEST_HALT_PROGRAM, halt),
    takeLatest(actionConstants.REQUEST_ANALYZE_PROGRAM, analyze),
    takeLatest(actionConstants.REQUEST_CLEAR_LOGS, deleteLogs),
    takeLatest(actionConstants.REQUEST_EXPLOITED_MACHINES, getExploitedMachines),
    takeLatest(actionConstants.REQUEST_PROGRAM_DEFINITIONS, getProgramDefinitions),
    takeLatest(actionConstants.REQUEST_HARDWARE_DEFINITIONS, getHardwareDefinitions),
    takeLatest(actionConstants.REQUEST_PURCHASE_PROGRAM, buyProgram)
  ]);

}